import Tooltip from 'tooltip.js'

class EmergiaTooltip extends HTMLElement {
  connectedCallback () {
    this.tooltip = new Tooltip(this, {
      template: `
        <emergia-tooltip-box class="tooltip" role="tooltip">
          <emergia-tooltip-arrow class="tooltip-arrow"></emergia-tooltip-arrow>
          <emergia-tooltip-inner class="tooltip-inner"></emergia-tooltip-inner>
        </emergia-tooltip-box>
      `,
      container: document.body,
      trigger: this._triggerMode(),
      closeOnClickOutside: true,
      placement: this.placement,
      popperOptions: {
        modifiers: {
          flip: {
            behavior: this._getFlipOptions(this.placement)
          },
          preventOverflow: {
            boundariesElement: 'viewport'
          }
        }
      },
      title: this.message,
      html: true
    })
  }

  get placement() {
    const possibilities = this._getFlipOptions()
    let selected = this.hasAttribute('placement') && this.getAttribute('placement')
    selected = possibilities.includes(selected) ? selected : 'top'
    return `${selected}`
  }

  get message() {
    return this.hasAttribute('message') && this.getAttribute('message')
  }

  _getFlipOptions (selected) {
    let options = ['top', 'right', 'bottom', 'left']
    if (options.includes(selected)) {
      delete options[options.indexOf(selected)]
    }
    return options.flat()
  }

  _triggerMode () {
    const isMobile = /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i.test(navigator.userAgent)
    return isMobile ? 'click' : 'hover focus click'
  }
}

export default EmergiaTooltip
